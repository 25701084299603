/* eslint-disable array-callback-return */
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * (Key, Value) <=> (Value is name in App and name in path)
 */
export enum IconName {
  layout1And1 = '1And1',
  layout1And2 = '1And2',
  layout1On1 = '1On1',
  layout1On2 = '1On2',
  layout2And1 = '2And1',
  layout2And2 = '2And2',
  layout2On1 = '2On1',
  layoutFull = 'full',
  down = 'down',
  info = 'info',
  line = 'line',
  menu = 'menu',
  mouse = 'mouse',
  oval = 'oval',
  pan = 'pan',
  print = 'print',
  printdisabled = 'print_disabled',
  polyline = 'polyline',
  freehand = 'freehand',
  save = 'save',
  saveMarkup = 'saveMarkup',
  shape = 'shape',
  text = 'text',
  tree = 'tree',
  edit = 'edit',
  close = 'close',
  explode = 'explode',
  cutting = 'cutting',
  zoomin = 'zoomin',
  zoomout = 'zoomout',
  cropfree = 'cropfree',
  autorenew = 'autorenew',
  flipcamera = 'flipcamera',
  rotateLeft = 'rotateLeft',
  rotateRight ='rotateRight',
  done = 'done',
  person = 'person',
  tag = 'tag',
  square = 'square',
  list = 'list',
  grid = 'grid',
  table = 'table',
  entitiesLine = 'entitiesLine',
  entitiesOval = 'entitiesOval',
  entitiesSquare = 'entitiesSquare',
  entitiesText = 'entitiesText',
  Bold = 'Bold',
  Underline = 'Underline',
  Italic = 'Italic',
  Font = 'Font',
  OrderedLists = 'OrderedLists',
  UnorderedLists = 'UnorderedLists',
  Size = 'Size',
  moreicon = 'moreicon',
  right = 'right',
  noVisible = 'noVisible',
  noMultiVisible = 'noMultiVisible',
  visible = 'visible',
  multiVisible = 'multiVisible',
  noPublishPdf = 'noPublishPdf',
  yesPublishPdf = 'yesPublishPdf',
  multiNoPublishPdf = 'multiNoPublishPdf',
  multiYesPublishPdf = 'multiYesPublishPdf',
  filter = 'filter',
  searchmk = 'searchmk',
  lib = 'lib',
  undoToLastSave ='undoToLastSave',
  undo = 'undo',
  redo = 'redo',
  cancel = 'cancel',
  design = 'design',
  folder = 'folder',
  floderOpen = 'folder-open',
  datepicker = 'datepicker',
  orbit = 'orbit',
  pdfThumnail = 'listthumbnail',
  home = 'home',
  zoom = 'zoom',
  zoomfit = 'zoomfit',
  standardview = 'standardview',
  notify = 'notify',
  rectangle = 'rectangle',
  arrow = 'arrow',
  callOut = 'callOut',
  cloud = 'cloud',
  note = 'note',
  highLight = 'highLight',
  moreHoriz = 'moreHoriz',
  fastForward = 'fastForward',
  chevronRight = 'chevronRight',
  menuMarkup = 'menuMarkup',
  deleteMarkup = 'deleteMarkup',
  leftIcon = 'leftIcon',
  fontDownload = 'fontDownload',
  create = 'create',
  drawmode = 'drawmode',
  walk = 'walk',
  back = 'back',
  top = 'top',
  bottom = 'bottom',
  front = 'front',
  left = 'left',
  standardRight = 'standard-right',
  hiddenLine = 'hidden_line',
  shaded = 'shaded',
  wireframe = 'wireframe',
  wireframeShaded = 'wireframe_on_shaded',
  xray = 'xray',
  boxFront = 'boxFront',
  boxBack = 'boxBack',
  boxBottom = 'boxBottom',
  boxTop = 'boxTop',
  boxLeft = 'boxLeft',
  boxRight = 'boxRight',
  squareFoot = 'squareFoot',
  measureAngle = 'measureAngle',
  measureBetweenFaces = 'measureBetweenFaces',
  measureEdges = 'measureEdges',
  measureP2P = 'measureP2P',
  setting = 'setting',
  planeX = 'planeX',
  planeY = 'planeY',
  planeZ = 'planeZ',
  planeMinusX = 'planeMinusX',
  planeMinusY = 'planeMinusY',
  planeMinusZ = 'planeMinusZ',
  Add = 'Add',
  ArrowDown = 'Arrow_down',
  ArrowUp = 'Arrow_up',
  Bell = 'Bell',
  Check = 'Check',
  CheckBoxBlank = 'Checkbox_Blank',
  CheckBoxChecked = 'Checkbox_Checked',
  ColorPicker = 'Color_picker',
  Cursor = 'cursor',
  Error = 'Error',
  File = 'File',
  screenshot = 'Screenshot',
  subtract = 'subtract',
  success = 'success',
  documentEdit = 'documentEdit',
  Visibility = 'Visibility',
  NorthWest = 'NorthWest',
  TopRight ='triangletopright',
  infoBlack = 'infoBlack',
  SectionToggle = 'SectionToggle',
  CancelSections = 'CancelSections',
  VectorDown = 'VectorDown',
  viewGrid = 'view_grid',
  viewList = 'view_list',
  sort = 'sort',
  lock = 'lock',
  fileEdit = 'file_edit',
  comment = 'comment',
  notifyBell = 'notify_bell',
  pencilEdit = 'pencil_edit',
  pushpin = 'pushpin',
  trash = 'trash',
  lines = 'lines',
  colors = 'colors',
  select = 'select',
  polygon = 'polygon',
  polygonCloud = 'polygoncloud',
  textNote = 'textNote',
  filter2 = 'filter_2',
  arrowBack = 'arrowBack',
  arrowDownward = 'arrowDownward',
  arrowForward = 'arrowForward',
  arrowUpward = 'arrowUpward',
  macCommand = 'macCommand',
  windowsStart = 'windowsStart',
  zoomwindow = 'zoomwindow',

  zoomprevious = 'zoomprevious',
  arrowUpShift = 'arrowUpShift',
  reverse = 'reverse',
  wholeWord = 'whole-word',
  nlock = 'nlock',
  exit = 'exit',
  cubeMultipleSides = 'cubeMultipleSides',
  ghostCube = 'ghostCube',
  textFields ='textFields',
  textstyle = 'textstyle',
  textfont = 'textfont',
  textcolor = 'textcolor',
  format = 'format',
  lineweight = 'lineweight',
  linestyle = 'linestyle',
  linecolor = 'linecolor',
  arrowstyle = 'arrowstyle',
  fillcolor = 'fillcolor',
  filltype = 'filltype',
  textboxvisibility = 'textboxvisibility',
  arc = 'arc',
  visibilityEye = 'visibilityEye',
  visibilityOffEye = 'visibilityOffEye',
  sourceList = 'source-list',
  formatBold = 'formatBold',
  formatItalic = 'formatItalic',
  formatUnderlined = 'formatUnderlined',
  newBold = 'newBold',
  basefont = 'basefont',
  signoff = 'signoff',
  keyboardArrowDown = 'keyboardArrowDown',
  keyboardArrowUp = 'keyboardArrowUp',
  draw = 'draw',
  PointToPoint = 'measureP2P',
  languageIcon = 'languageIcon',
  languageIcon1 = 'languageIcon1',
  expandLess = 'expandLess',
  expandMore = 'expandMore',
  GroupActive = 'GroupActive',
  contentCopy = 'contentCopy',
  warning = 'warning',
  saveAlt = 'saveAlt',
  attachFile = 'attachFile',
  publishPDF = 'publishPDF',
  DeleteSweepIcon = 'DeleteSweepIcon',
  MeasureList = 'MeasureList',
  SyncView = 'syncView',
  ZoomVertical = 'zoomVertical',
  ZoomHorizontal = 'zoomHorizontal',
  symbols = 'symbols',
  arrowForwardIOS = 'arrowForwardIOS',
  frozen = 'frozen',
  lightBulbOff = 'lightBulbOff',
  lightBulbOn = 'lightBulbOn',
  sunny = 'sunny',
}
@Injectable({
  providedIn: 'root',
})
export class IconSvgService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
  }

  private readonly path = '/assets/synergis-icon';

  public registrySvgIcon() {
    Object.values(IconName).forEach((nameIcon) => {
      this.iconRegistry.addSvgIcon(
        nameIcon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`${this.path}/${nameIcon}.svg`),
      );
    });
  }
}
