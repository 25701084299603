import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'open',
    pathMatch: 'full',
  },
  {
    path: 'main',
    loadChildren: () => import('./main-viewer/main-viewer.module').then((m) => m.MainViewerModule),
  },
  {
    path: 'add-to-session',
    loadChildren: () => import('./add-to-session/add-to-session.module').then((m) => m.AddToSessionModule),
  },
  {
    path: 'print',
    redirectTo: 'main/creator',
  },
  {
    path: 'open',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'pdf',
    loadChildren: () => import('./pdf/pdf.module').then((m) => m.PDFModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'pdf-viewer-test',
    loadChildren: () => import('./pdf-viewer-test/pdf-viewer-test.module').then((m) => m.PdfViewerTestModule),
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
