import { ArrowStyle, LineStyle } from 'src/app/core/common/ad.setting.viewer3d';
import {
  TypeLayout, ViewType, SavedViews, UserInfo, LayerData,
} from 'src/app/core/common/main-viewer-common';
import { MarkupMode, MarkupFillType, MarkupStatus } from 'src/app/core/common/markups';
import { ETypeView } from 'src/app/core/common/3dviewer-common';
import { StandardFont } from 'src/@pdf_typings/foxit';
import { NameOfDataChange } from '../../core/services/setting-data-manager.service';
import { OptionData } from '../common/shared.common';
import { PDF } from 'src/assets/foxit-lib/PDFViewCtrl';
type PDFPage = PDF.PDFPage;

export enum MarkupType {
  Unknown = -1,
  Freehand = 0,
  Square = 1,
  Oval = 2,
  Text = 3,
  Arrow = 4,
  Line = 5,
  CallOut = 6,
  Cloud = 7,
  Note = 8,
  HighLight = 9,
  NotePin = 10,
  Polygon = 11,
  Polyline = 12,
  PolygonCloud = 13,
  TextNote = 14,
  None = 15,
  Arc = 16,
  Signoff = 17,
  Measure = 18,
  PointToPoint = 19,
  Attachment = 20,
  Symbols = 21,
  CircleDimension = 22,
  PolygonDimension = 23,
  PolyLineDimension = 24,
  Highlighter= 25,
}

export enum eMarkupContextMenuStatus {
  zoomSelect = 1,
  addToGroup,
  removeFromGroup,
  show,
  hide,
  delete,
  rotateTextBox,
  unRotateTextBox
}

export enum MeasureName {
  MeasureFaceFaceAngle = 'MeasureFaceFaceAngle',
  MeasureFaceFaceDistance = 'MeasureFaceFaceDistance',
  LineDimension = 'LineDimension',
  CircleDimension = 'CircleDimension',
  PolygonDimension = 'PolygonDimension',
  PolyLineDimension = 'PolyLineDimension',
}

export enum TypeContextMenu3DViewer {
  IsoLate = 'Isolate',
  ZoomFit = 'Zoom Fit',
  ZoomIn = 'Zoom In',
  ZoomOut = 'Zoom Out',

  ZoomPrevious = 'Zoom Previous',
  ZoomSelected = 'Zoom Selected',
  ZoomVertical = 'Zoom Vertical',
  ZoomHorizontal = 'Zoom Horizontal',
  ZoomSelectedMarkup = 'Zoom Selected',
  AddMarkupToGroup = 'Add to Group',
  RemoveMarkupFromGroup = 'Remove from Group',
  Hide = 'Hide',
  Show = 'Show',
  HideMarkup = 'Hide',
  ShowMarkup = 'Show',
  DeleteMarkup = 'Delete',
  ShowXYZ = 'Show XYZ',
  HideXYZ = 'Hide XYZ',
  Transparent = 'Set Transparency',
  SetColor = 'Set Color',
  UnsetColor = 'Unset Color',
  ModifyColor = 'Modify Color',
  ShowHandles = 'Show Handles',
  AdjustOrbitCenter = 'Adjust Orbit Center',
  ReleaseCenter = 'Release Center',
  HoldCenter = 'Hold Center',
  ResetModel = 'Reset Model',
  ShowAll = 'Show All',
  Test = 'Test',
  SaveView = 'Save View',
  CopyMarkup = 'Copy',
  PasteMarkup = 'Paste',
  RotateTextbox = 'Rotate Text Box',
  UnRotateTextbox = 'Cancel Rotate Text Box',
  Comparison = 'Comparison',
  Comparison2D = 'Comparison2D',
  Comparison3D = 'Comparison3D',
  OverlayCompare = 'Overlay Compare',
  ExitCompareMode = 'Exit Compare Mode',
  Attachment = 'Attachment',
  OpenAttachment = 'Open Attachment',
  OpenAttachmentWith = 'Open Attachment With',
  SaveAsAttachment = 'Save As Attachment',
  NewWindow = 'New Window',
  CurrentWindow = 'Current Window',
  AssociatedApplication = 'Associate Application',
  MeasureEndcapType = 'Measure Endcap Type',
  None = 'None',
  Arrowhead = 'Arrow Head',
  Circle = 'Circle',
  Monochrome = 'Monochrome',
  ExitMonochromeMode = 'ExitMonochromeMode',
  TextSearch = 'Text Search',
}

export enum ZoomType {
  ZoomIn = 0,
  ZoomOut = 1,
  ZoomFit = 2,
  ZoomSelected = 3,
  ZoomInKeyboard = 4,
  ZoomOutKeyboard = 5,
  Other = 6,
  ZoomVertical = 7,
  ZoomHorizontal = 8,
}

export enum ZoomAction {
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut',
  ZoomFit = 'ZoomFit',
  Other = 'Other',
  ZoomVertical = 'ZoomVertical',
  ZoomHorizontal = 'ZoomHorizontal',
}

export enum OpenType {
  pdfDoc,
  pdfDrawing,
}

export enum ActionType {
  Add,
  Update,
  Remove,
  Load,
}

export enum PDFWaterMarkFont {
  Courier = 0,
  CourierBold = 1,
  CourierBoldOblique = 2,
  CourierOblique = 3,
  Helvetica = 4,
  HelveticaBold = 5,
  HelveticaBoldOblique = 6,
  HelveticaOblique = 7,
  TimesRoman = 8,
  TimesBold = 9,
  TimesBoldItalic = 10,
  TimesItalic = 11,
  Symbol = 12,
  ZapfDingbats = 13
}

export enum PDFAnnotFlag {
  Invisible = 1,
  Hidden = 2,
  Print = 4,
  NoZoom = 8,
  NoRotate = 16,
  NoView = 32,
  ReadOnly = 64,
  Locked = 128,
  ToggleNoView = 256,
  LockedContents = 512
}

export enum MouseType {
  MouseLeft = 0,
  MouseMiddle = 1,
  MouseRight = 2,
  NoneSelect = 3,
}

export enum DataStatus {
  LoadSetting,
  ChangeSetting,
  SaveSetting,
  ChangeFormat,
  SetFormat,
}

export enum Navigate {
  Previous = 0,
  Next = 1,
}

export enum MarkupEditType {
  font,
  border,
}

export enum FormatButtonType {
  textStyle = 'textStyle',
  fontStyle = 'fontStyle',
  textColor = 'textColor',
  lineWeight = 'lineWeight',
  lineStyle = 'lineStyle',
  arrowStyle = 'arrowStyle',
  lineColor = 'lineColor',
  fillColor = 'fillColor',
  fillType = 'fillType',
  textBoxVisibility = 'textBoxVisibility'
}

export enum FormatSettingType {
  textStyle = 'textStyle',
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  fontStyle = 'fontStyle',
  fontFamily = 'fontFamily',
  fontSize = 'fontSize',
  textColor = 'textColor',
  lineWeight = 'lineWeight',
  lineStyle = 'lineStyle',
  arrowStyle = 'arrowStyle',
  lineColor = 'lineColor',
  fillColor = 'fillColor',
  fillType = 'fillType',
  fillOpacity = 'fillOpacity',
  fillTypeAndOpacity = 'fillTypeAndOpacity',
  textBoxVisibility = 'textBoxVisibility',
}

export enum FlyoutType {
  arrayBtn = 'arrayBtn',
  dropdownList = 'dropdownList',
  multiDropdownList = 'multiDropdownList',
  colorPalette = 'colorPatette'
}

export enum PDFSearcherType {
  any = 0,
  caseSensitive = 1,
  wholeWord = 2,
  consecutively = 4,
}

export enum eViewMode {
  normal = 0,
  monochrome = 1
}

export interface DataMarkupView {
  name: string;
  id: Communicator.Uuid;
  uniqueId?: Communicator.Uuid;
  size?: number;
  url?: string;
  viewTag?: ViewTag[];
  viewAuthor?: AuthorInfo[];
  createdBy?: AuthorInfo;
  viewStatus?: number;
  dataMarkup?: DataMarkupItem[];
  createDate?: string;
  description?: string;
}

export interface ViewTag {
  name?: string;
  color?: number;
}

export interface AuthorInfo {
  name?: string;
  userId?: string;
}

export interface DataMarkupItem {
  name?: string;
  type?: number;
  id: Communicator.Uuid;
  author?: AuthorInfo;
  createDate?: string;
  status?: number;
}

export interface DataDialog {
  setShow: boolean;
  message?: string;
  result?: boolean;
  parent?: string;
}

export enum CreatingState {
  Creating = 0,
  Created = 1,
}

export interface DataFilter {
  authorName?: string;
  date?: DateRange;
  tag?: string;
  status?: number[];
}

export interface DateRange {
  begin?: number;
  end?: number;
}

export interface DataFilterMarkupDialog {
  authors: string[];
  tags: string[];
  dataFilter?: DataFilter;
}

export interface MarkupItemFormatData {
  type?: NameOfDataChange;
  textSize?: number;
  textColor?: string;
  thickness?: number;
  color?: string;
  fontFamily?: string;
  fillType?: MarkupFillType;
  fillOpacity?: number;
  fillColor?: Communicator.Color;
  lineStyle?: LineStyle;
  arrowStyle?: ArrowStyle;
  textboxVisibility?: boolean;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
}

export interface DataFilterForButtom {
  entities?: any[];
  status?: any[];
}
export interface DataFilterButtom {
  entities: string[];
  status: string[];
}
export interface Point {
  x: number;
  y: number;
}
export interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Size {
  width: number;
  height: number;
  scale?: number;
}

export const inchToMillimetersr = 25.4;

export interface Boundary {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface ViewImage {
  width: number;
  height: number;
  img: string;
}
export interface ViewProperty extends SavedViews {
  viewRectangle: Boundary;
  scale?: number;
  layout?: TypeLayout;
}

export interface CustomBookmark extends SavedViews {
  id: string;
  rect: Boundary;
  scale: number | string;
  title: string;
  page: number;
  pageRotation: number;
}

export interface ZoomView extends SavedViews {
  id: string;
  rect: Boundary;
  scale: number | string;
  title: string;
  page: number;
}
export interface MarkupCopy {
  viewId?: string;
  sheetId?: number;
  data?: string;
  dataArr?: string[];
  boundary?: Boundary;
  is3D?: boolean;
  pageDirection?: number;
  rotation?: number;
  conversionToolkit?: string;
}

export enum FontStyle {
  normal = 0,
  bold = 1,
  italic = 2,
  underline = 4,
}

export interface PrintParam {
  watermark: HTMLElement;
  textElement: HTMLElement;
  waterMarkHeaderConfig: any;
  pageSize: Size;
  scale?: number;
}

export enum SectionStatus {
  NoFaceCutOff,
  PresentCutOff,
  ReversesTheCutOfTheCutters,
}
export interface DataObjectEntity {
  uniqueId: string;
  status: MarkupStatus;
  type?: MarkupType;
  name?: string;
  createdBy: UserInfo;
  createdDate: number;
  modifiedDate: number;
  title?: string;
  objectType: ObjectType;
  ModelFileId?: string;
  originData?: any;
  uniqueViewId?: string;
  modeMarkup?: MarkupMode;
  originId?: string,
  readonly?: boolean,
}

export enum ObjectType {
  group,
  markup,
}

export interface ContextMenuObjectEntity {
  objectType: ObjectType;
  mouseEvent: MouseEvent;
  isInGroup?: boolean;
  markupId?: string;
}

export interface CheckedData {
  objectType: ObjectType;
  isChecked: boolean;
  objectId: string;
}
export interface ItemSelect {
  objectType: ObjectType;
  objectId: string;
  data: any;
  event?: MouseEvent;
  isSelect?: boolean;
}

export enum SelectedTabs {
  object = 'object',
  groups = 'groups',
}

export enum ePageSize {
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7
}

export enum EColorOption {
  Color = 0,
  Mono = 1,
  Grayscale = 2,
}

export enum ViewMode {
  CONTINUOUS = 'continuous-view-mode',
  FACEING_CONTINUOUS = 'facing-continuous-page-view-mode',
  FACEING_COVER_CONTINUOUS = 'facing-cover-continuous-view-mode',
  FACEING_COVER = 'facing-cover-view-mode',
  FACEING_PAGE = 'facing-page-view-mode',
  MULTI_PAGE = 'multipage-view-mode',
  SINGLE_PAGE = 'single-page-view-mode',
}

export interface InterfacePrintView {
  hasMarkup: boolean;
  name: string;
  shortname?: string;
  typeView: ETypeView; // 0: markupView, 1: cadView, 2: custormView
  viewId: Communicator.Uuid; // hoop viewID
  groupId: Communicator.Uuid; // groupID: groupId & viewId chỉ có 1 field khác rỗng
  isPrintMarkup: boolean;
  isPrint: boolean;
  pageIndex: number;
  fileViewId: string;
  printInfor: any; // group, cadview, saveview, viewProperty, pdfPage, fileInfor
}

export enum EPageArea {
  extents = 0,

  currentSheet = 1,

  display = 2
}

export enum EPageLayout {
  automatic = 0,

  landscape = 1,

  portrait = 2
}

export interface IPrintOption {
  /**
   * 0: extents, 1: currentSheet, 2: display
   */
  pageArea: number;
  /**
   * 0: automatic, 1: landscape, 2: portrait
   */
  layout: number;

  /**
   * < 0: export all page
   * >= 0: export select page
   */
  pageSelected?: number; // page number
  /**
   * 0: printview, 1: print group
   */
  typePrint?: number;

  /**
   * 0: Fit to Page, 1: custom
   */
  scaling?: number;
  scalingValue?: number; // 0 - 100

  /**
   * 0: color, 1: monochrome, 2: grayscale
   */
  color: EColorOption;
  /**
     * pageSize
     */
  pageIndex?: number;

  pageSizeType?: number;

  size?: Size;

  autoSize?: boolean; // one pagesize for all sheet or automatic

  pen_override?: boolean;

  pen_name?: string;

  includesPage?: number[]
}

export interface PrintCreatorParam {
  token?: string;
  id: string;
  baseFileId: string;
  baseMajorRev: number;
  baseMinorRev: number;
  direct: boolean;
  width3d: number; // < 0: all, > 0: pageIndex
  height3d: number; // < 0: all, > 0: pageIndex
}

export enum eHelpType {
  PDF = 1,
  HTML = 2,
  ADEPTPDF = 3,
  ADEPTHTML = 4,
}

export interface MarkupsContextMenu {
  mouseEvent: MouseEvent;
  markups: any[];
}

export interface PointColor {
  r: number;
  b: number;
  g: number;
  a: number;
}

export interface CircleData {
  center: Point;
  radius: number;
}

/**
 * PrintParam
 *
 */
export interface IViewParamsModel { // old: PrintParameter
  userLoginName: string;
  userId: string;
  baseFileId: string;
  baseMajorRev: number;
  baseMinorRev: number;
  fileName: string;
  originalFilePath: string;
  viewItemParameters: IViewItemParameters; // old: PrintDetail
  extendParameters?: ExtendParameters;
}

export interface ExtendParameters {
  id?: string;
  // eslint-disable-next-line camelcase
  pen_name: string;
}
export interface IViewItemParameters { // old: PrintDetail
  operation: string;
  autoLoadMarkup: boolean;
  loadFit: boolean;
  renderingColor: string;
  /// <summary> See the Permissions Class and the annotations on its fields. </summary>
  permissions?: IPermissions;
  printHeader: IPrintHeader;
  watermark: IWatermark;
  autovueRedlineConversionData: string;
  markupData: string;
  pageSizeType: number;
  printOption?: IPrintOption; // thong tin bo sung of hicas
  printExtraData?: IPrintExtraData;
  serverSideConvert: boolean;
}

export interface IPermissions {
  canConvert: boolean | any;
  canPrint: boolean | any;
  canRedline: boolean | any;
  canOpenRedline: boolean | any;
  canEditRedline: boolean | any;
  canDeleteRedline: boolean | any;
  canSaveExistingRedline: boolean | any;
  canSaveNewRedline: boolean | any;
  canSaveToPDF: boolean | any;
  canCompare: boolean | any;
}

export interface IPrintHeader {
  topLeftText: string;
  topCenterText: string;
  topRightText: string;
  bottomLeftText: string;
  bottomCenterText: string;
  bottomRightText: string;
  fontSize: number;
  disableHeaders: boolean;
}
export interface IWatermark {
  text: string;
  fontName: string;
  fontSize: number;
  fontStyle: number;
  orientation: number;
  position: string;
  xFactor: number;
  yFactor: number;
  color: string;
  alpha: number;
  disableWatermark: boolean;
}

export interface IPrintExtraData {
  mapListLayerNonPrintable: Record<string, LayerData[]>; // Dictionary<string, LayerData[]>
  // ignoreLineWeight?: boolean;
  // [2024/11/03][AVD-7799][phuong_td] add plotLineWeight
  plotLineWeight?: number;
  exportOriginLayer: boolean;
}

export enum eNameDataGet {
  bold = 1,
  underLine,
  italic,
  fontName,
  fontSize,
  fontColor,
  lineWeight,
  lineStyle,
  arrowStyle,
  lineColor,
  fillColor,
  fillType,
  fillOpacity,
  textboxVisibility
}

export interface CanvasData {
  buffer: Uint8Array | Uint16Array | Uint32Array | Uint8ClampedArray;
  width: number;
  height: number;
}

export interface iResultPdfAnnotsSnapshot {
  page: PDFPage, 
  annots: any[]
}
export enum etypeSignoff {
  create = 'Created By:',
  dropped = 'Dropped By:',
  approved = 'Approved By:',
  rescinded = 'Rescinded By:'
}
export interface ISignoff {
  type: etypeSignoff;
  by: string;
  on: string;
}
export interface ISignoffInfo {
  current: ISignoff;
  create: ISignoff;
  history: ISignoff[];
}

export enum openAttachType {
  newWindow = 1,
  currentWindow,
  associatedApplication,
}

export interface IAttachData {
  linkName?: string;
  description?: string;
  URL?: string;
  openIn?: openAttachType;
  from?: string;
  fileName?: string;
  author?: string;
  date?: string;
}

export enum DirectionExtend {
  both = 0,
  top = 1,
  left = 2,
  bottom = 3,
  right = 4,
}

export enum CursorPanOperator {
  grab = 'grab',
  grabbing = 'grabbing',

  default = 'default',
  zoom = 'url("./assets/cursor/zoom_window.cur"), auto',
  pointer = 'pointer'
}

export enum OpenFileType {
  PDF = 1,
  PNG = 2,
  BLANK = 3,
}

export interface ViewState {
  zoomAction?: string;
  viewId: string; // viewId or page number
  sheetId?: number; // sheetId or pageNumber
  x?: number;
  y?: number;
  scale?: number;
  rotate?: number;
  rect?: any;
  camera?: Communicator.Camera;
}

export enum unitsSymbol {
  Point = 'points',
  Inch = 'inch',
  Millimeter = 'mm',
  Centimeter = 'cm',
  Decimeter = 'dm',
  Dekameter = 'dam',
  Hectometer = 'hm',
  Picas = 'picas',
  Feet = 'ft',
  Yard = 'yd',
  Meter = 'm',
  Kilometer = 'km',
  Mile = 'mi',
  Megameter = 'Mm',
  Picometer = 'pm',
  MicroInch = 'µin',
  Mil = 'mil',
  Angstroms = 'Å',
  Nanometer = 'nm',
  Micrometer = 'μm',
  Gigameters = 'Gm',
  AstronomicalUnits = 'au',
  LightYears = 'ly',
  Parsec = 'pc',
  USSurveyFeet = 'USft',
}

export enum eUnitsName {
  Point = 'Point',
  Inch = 'Inch',
  Millimeter = 'Millimeter',
  Centimeter = 'Centimeter',
  Decimeter = 'Decimeter',
  Dekameter = 'Dekameter',
  Hectometer = 'Hectometer',
  Picas = 'Picas',
  Feet = 'Feet',
  Yard = 'Yard',
  Meter = 'Meter',
  Kilometer = 'Kilometer',
  Mile = 'Mile',
  Megameter = 'Megameter',
  Picometer = 'Picometer',
  MicroInch = 'MicroInch',
  Mil = 'Mil',
  Angstroms = 'Angstroms',
  Nanometer = 'Nanometer',
  Micrometer = 'Micrometer',
  Gigameters = 'Gigameters',
  AstronomicalUnits = 'Astronomical Units',
  LightYears = 'Light Years',
  Parsec = 'Parsec',
  USSurveyFeet = 'US Survey Feet',
}

export enum UnitMultiplier {
  Millimeter = 1,
  Centimeter = 10,
  Decimeter = 1e2,
  Meter = 1e3,
  Dekameter = 1e4,
  Hectometer = 1e5,
  Kilometer = 1e6,
  Megameter = 1e9,
  Point = 25.4 / 72,
  Inch = 25.4,
  MicroInch = 0.0000254,
  Mil = 0.0254,
  Picas = 25.4 / 6,
  Feet = 304.8,
  Yard = 914.4,
  Mile = 1609344,
  Picometer = 0.000000001,
  Angstroms = 0.0000001,
  Nanometer = 0.000001,
  Micrometer = 0.001,
  Gigameters = 1e12,
  AstronomicalUnits = 149597870691000,
  LightYears = 9460730472580043000.0,
  Parsec = 30856775812799586000.0,
  USSurveyFeet = 304.8005998463808,
}

export enum UnitMultiplierSquare {
  Millimeter = 1,
  Centimeter = 1e2,
  Decimeter = 1e4,
  Meter = 1e6,
  Dekameter = 1e8,
  Hectometer = 1e10,
  Kilometer = 1e12,
  Megameter = 1e18,
  Point = (25.4 / 72) ** 2,
  Inch = 25.4 ** 2,
  MicroInch = 0.0000254 ** 2,
  Mil = 0.0254 ** 2,
  Picas = (25.4 / 6) ** 2,
  Feet = 304.8 ** 2,
  Yard = 914.4 ** 2,
  Mile = 1609344 ** 2,
  Picometer = 1e-9 ** 2,
  Angstroms = 1e-7 ** 2,
  Nanometer = 1e-6 ** 2,
  Micrometer = 1e-3 ** 2,
  Gigameters = 1e12 ** 2,
  AstronomicalUnits = 149597870691000 ** 2,
  LightYears = 9460730472580043000 ** 2,
  Parsec = 30856775812799586000 ** 2,
  USSurveyFeet = 304.8005998463808 ** 2,
}

export enum GroupContextMenu3DViewer {
  Zoom = 'Zoom',
  Markup = 'Markup',
  Manipulation = 'Manipulation',
  Global = 'Global',
  Model = 'Model',
  Copy = 'Copy',
}
export interface ContextMenuItem<T> {
  Name: T;
  Type: T;
  Display: string;
  Disable: boolean;
  Hide: boolean;
  click?: any;
  SubMenu?: ContextMenuItem<T>[];
}

export interface ContextMenuGroup<T> {
  Name?: string;
  Items: ContextMenuItem<T>[];
}

export enum eScaling {
  ISOTROPIC = 1,
  ANISOTROPIC
}

export interface ISymbol {
  Url: string;
  DataUri?: string;
  Id: any;
  Name: string;
  Description?: string;
  UrlType: number;
  Scaling?: eScaling;
  scaleOrigin?: number;
}

export enum MarkupAttachType {
  None = 1,
  Face = 2,
  Vertex = 3,
  Edge = 4,
}

export interface ISize {
  width: number;
  height: number;
}

export interface IStandard {
  name: string
  width: number
  height: number
  scale: number
  value?: number
}

export enum eModifineType {
  setColor,
  unSetColor 
}

export interface iModifineColorNode {
  type: eModifineType;
  nodes: number[];
  color?: Communicator.Color;
}

export enum eErrorType {
  NoError = 1,
  ViewOnly,
  NumberOfOpenFiles,
  AdvancedCompare, // dung oda (ctrl press + normal click)
  OverlayCompare, // dung hoop (normal click)
  CantCreateComapre,
  NoSupport
}

export enum eTypeServer {
  SERVER_WEB = 'SERVER_WEB',
  SERVER_STREAM = 'SERVER_STREAM',
  SERVER_CONVERSION = 'SERVER_CONVERSION',
  SERVER_BIM = 'SERVER_BIM',
  SERVER_IDENTITY = 'SERVER_IDENTITY',
  SERVER_ADEPT = 'SERVER_ADEPT'
}

export const mapStandardFont = new Map([
  ['courier', new Map([
    [0, StandardFont.courier],
    [1, StandardFont.courierBold],
    [2, StandardFont.courierBoldOblique],
    [3, StandardFont.courierOblique],
  ])],
  ['helvetica', new Map([
    [0, StandardFont.helvetica],
    [1, StandardFont.helveticaBold],
    [2, StandardFont.helveticaBoldOblique],
    [3, StandardFont.helveticaOblique],
  ])],
  ['times', new Map([
    [0, StandardFont.timesRoman],
    [1, StandardFont.timesBold],
    [2, StandardFont.timesBoldItalic],
    [3, StandardFont.timesItalic],
  ])],
  ['symbol', new Map([
    [0, StandardFont.symbol],
  ])],
  ['zapfdingbats', new Map([
    [0, StandardFont.zapfDingbats],
  ])],
]);

export const MARKUP_LAYER_RENDER_IMAGE_TIMEOUT = 10000;
export interface CadViewAction {
  isSelectedFromPanel: boolean;
  cadViewId: number;
}

interface RichTextStyle {
  font? : {
    name : string;
  }
  textSize?: number;
  textAlignment?: 0 | 1 | 2;
  textColor?: number | string;
  isBold?: boolean;
  isItalic?: boolean;
  isUnderline?: boolean;
  isStrikethrough?: boolean;
  cornerMarkStyle?: 1 | 2 | 3;
}

export interface RichTextData {
  index?: number;
  content?: string;
  richTextStyle?: RichTextStyle;
}
export const DEFAULT_HIGHLIGHTER_COLOR = '#ffff00';


export enum Copied_Markup_Storage {
  MEM = 1,
  LOCAL = 2,
  CLIPBOARD = 4
}

export enum Page_Direction {
  Landscape = 1,
  Portrait = 2
}

export enum ConversionToolkit {
  FOXIT = 'FOXIT',
  HOOPS = 'HOOPS'
}
