/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SystemConstants } from '../../common/system.constants';
import { APIService } from './base/APIBase.service';
import Util from '../../utils/util';
import { FileInfo } from '../../common/main-viewer-common';

@Injectable({
  providedIn: 'root',
})
export class StreamService extends APIService {
  // da khai bao trong base
  // public BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_STREAM.HOST}`;

  constructor(public http: HttpClient, public router: Router) {
    super(http, router);
  }

  public getAllFiles(path) {
    return this.get(SystemConstants.SERVER_STREAM.API.FILES.GET + path);
  }

  public getInformations() {
    return this.get(SystemConstants.SERVER_STREAM.API.INFORMATIONS.GET);
  }

  public addFileCache(data: any) {
    return this.post(SystemConstants.SERVER_STREAM.API.CACHE.POST, data);
  }

  public registerBroker(data) {
    return this.post(SystemConstants.SERVER_STREAM.API.SPAWN.POST, data);
  }

  public getAllFileCache(data: any) {
    return this.post(SystemConstants.SERVER_STREAM.API.CACHE.POST, data);
  }

  public downloadFile(file) {
    return this.getFile(`${SystemConstants.SERVER_STREAM.API.FILES.DOWNLOAD}?${Util.parseUrl(file).toString()}`);
  }

  public getContentFile(file) {
    return this.get(`${SystemConstants.SERVER_STREAM.API.FILES.DOWNLOAD}?${Util.parseUrl(file).toString()}`);
  }

  public getFileInfo(file) { // nghia_td add warning dialog
    return this.get(`${SystemConstants.SERVER_STREAM.API.FILES.DOWNLOAD}?${Util.parseUrl(file).toString()}`);
  }

  public getUrlImage(file: FileInfo) {
    return `${this.BaseApi}/${
      SystemConstants.SERVER_STREAM.API.FILES.DOWNLOAD}?${
      Util.parseUrl({
        path: file.thumbnailLocation,
      }).toString()}`;
  }

  public getUrlFromLocation(location) {
    return `${this.BaseApi}/${
      SystemConstants.SERVER_STREAM.API.FILES.DOWNLOAD}?${Util.parseUrl(location).toString()}`;
  }

  // [28/02/2024][ADV-6926][phuong_td] Create URL to get PDF file using Range Range
  public getUrlFromLocationByStream(location: {path: string}) {
    const path = location.path.replace('./', '');
    return encodeURI(`${SystemConstants.SERVER_WEB.HOST}/${path}`);
  }

  public checkPathExist(path, apiUrl = null) {
    if (!path || path === Util.stringEmpty) return false;

    //if the url is provided, use that instead
    const baseApi = apiUrl || this.BaseApi;
    const url = `${baseApi}/${SystemConstants.SERVER_STREAM.API.FILES.CHECK_PATH_EXIST}?${Util.parseUrl(path).toString()}`;
    const http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
  }
}

export class StreamFile {
  public filename: string;

  public baseFileId = '';

  public baseMajorRev = 0;

  public baseMinorRev = 0;

  public isChecked = false;

  public status: any;

  public isDirectory: boolean;

  public createdDate: string;

  public cacheStatus: number;
}
