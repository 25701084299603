/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { SystemConstants } from '../../common/system.constants';
import { StreamService } from './stream.service';
import { ConversionService } from './conversion.service';
import { BimService } from './bim.service';
import { IdentityService } from './identity.service';
import { WebServerService } from './webserver.service';
import { AdeptService } from './adept.service';

interface SystemConfig {
  PROTOCOL: string;
  SERVER_WEB: {
    HOST: string;
  };
  SERVER_STREAM: {
    HOST: string;
  };
  SERVER_CONVERSION: {
    HOST: string;
  };
  SERVER_BIM: {
    HOST: string;
  };
  SERVER_IDENTITY: {
    HOST: string;
  };
  SERVER_ADEPT: {
    HOST: string;
  };
  RENDER: string;
  VIEWER_VERSION: string;
  VIEWER_COPYRIGHT: string;
  HIDE_HAMBURGER_MENU: boolean;
  HOST_TYPE: string;
  MAX_STREAMER_INACTIVE: number;
  MAX_NODE: number;
  PDF_EXPORT: {
    RENDER: string;
    STREAM_CUTOFF_SCALE: number;
    BOUNDING_PREVIEW: Communicator.BoundingPreviewMode;
    MEMORY_LIMIT: number;
    MAX_TASK: number;
    EXP_DIRECTLY: boolean;
    EXP_3DAS2D: boolean;
    PAGE_SIZE_TYPE: number;
  };
  CANVAS_DPI: number;
  PEN_OVERRIDE: string | null;

  // John Lee _ 04/04/2024 _ Used to allow editing of HeaderFooterWatermark in publishPDF dialog
  ALLOW_OVERRIDE_HEADER_FOOTER_WATERMARK: boolean;
  BUILD_VERSION: string;
  FOXIT_VIEWER_SETTINGS: {
    TILE_CACHE: number; // The tile caching is enabled, tiles that scroll outside the viewport are retained
    READ_BLOCK: number; // Size of each read block. default 8192 = 8 *1024 = 8KB,
    OPEN_BY_HTTP_RANGE: boolean; // 2025-03-12_phuong_td_variable to determine to open PDF files with the server file or download the whole file
    CHUNK_SIZE: number; // Size of each Range sharding request. default 131072 = 128 *1024 = 128KB
  }

  WAIT_FOR_RENDER_MARKUP_TIMEOUT: {
    MAX_WAIT_TIME: number,
    DYNAMIC_CHECK_INTERVAL: number,
  }
  // [16/05/2024][ADV-7375][phuong_td] Multipler declaration for highlighter
  HIGHLIGHTER : {
    LINEWEIGHT_MULTIPLER: number,
    FILL_OPACITY_MULTIPLER: number,
  }
  COPIED_MARKUP_STORAGE:  number;
}

const SYSTEM_CONFIG_TEST: SystemConfig = {
  PROTOCOL: 'http',
  SERVER_WEB: {
    HOST: '3dviewer.construxiv.com',
  },
  SERVER_STREAM: {
    HOST: '3dviewer.construxiv.com/server',
  },
  SERVER_CONVERSION: {
    HOST: '3dviewer.construxiv.com/service/conversion',
  },
  SERVER_BIM: {
    HOST: '3dviewer.construxiv.com/service/BIM',
  },
  // [12/08/2024][ADV-7726][phuong_td] Set the HOST by empty
  SERVER_IDENTITY: {
    HOST: '',
  },
  SERVER_ADEPT: {
    HOST: 'adept.anybim.vn/Synergis.WebApi',
  },
  RENDER: 'csr',
  VIEWER_VERSION: '100.0.5.1',
  VIEWER_COPYRIGHT: 'Copyright 2020 Synergis Technologies, LLC. All rights reserved.',
  HIDE_HAMBURGER_MENU: false,
  HOST_TYPE: 'CONSTRUXIV',
  MAX_STREAMER_INACTIVE: 2,
  MAX_NODE: 0,
  PDF_EXPORT: {
    RENDER: 'scs',
    STREAM_CUTOFF_SCALE: 0.0125,
    BOUNDING_PREVIEW: Communicator.BoundingPreviewMode.None,
    MEMORY_LIMIT: 0,
    MAX_TASK: 3,
    EXP_DIRECTLY: true,
    EXP_3DAS2D: true,
    PAGE_SIZE_TYPE: -1,
  },
  CANVAS_DPI: 288,
  PEN_OVERRIDE: null,
  ALLOW_OVERRIDE_HEADER_FOOTER_WATERMARK: false,
  BUILD_VERSION: '',
  FOXIT_VIEWER_SETTINGS: {
    TILE_CACHE: 2,
    READ_BLOCK: 8192,
    OPEN_BY_HTTP_RANGE: false,
    CHUNK_SIZE: 131072,
  },
  WAIT_FOR_RENDER_MARKUP_TIMEOUT: {
    MAX_WAIT_TIME: 10000,
    DYNAMIC_CHECK_INTERVAL: 100,
  },
  HIGHLIGHTER: {
    LINEWEIGHT_MULTIPLER: 12,
    FILL_OPACITY_MULTIPLER: 0.5,
  },
  COPIED_MARKUP_STORAGE: 1
};

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private readonly path = './assets/data/system-config.json';

  public _config = new BehaviorSubject<boolean>(false);

  public config$ = this._config.asObservable();

  public viewerVersion = '';

  public viewerCopyright = '';

  constructor(
    private http: HttpClient,
    private webServerService: WebServerService,
    private streamService: StreamService,
    private conversionService: ConversionService,
    private bimService: BimService,
    private identityService: IdentityService,
    private adeptService: AdeptService,
  ) { }

  updateConfig(value: boolean) {
    this._config.next(value);
  }

  config() {
    this.http
      .get(`${this.path}`)
      .pipe()
      .subscribe(
        (value: SystemConfig) => {
          const currVal = this.isTestConstruxiv ? SYSTEM_CONFIG_TEST : value;
          this.setConfig(currVal);
        },
        (err) => this.handlerErr(err),
      );
  }

  /** private */
  private setConfig(value: SystemConfig) {
    const protocol = value.PROTOCOL ?? SystemConstants.PROTOCOL;
    SystemConstants.PROTOCOL = protocol;
    // [12/08/2024][ADV-7726][phuong_td] Check null before putting the value for Systemconstants
    if (value.SERVER_WEB) {
      SystemConstants.SERVER_WEB = {
        ...SystemConstants.SERVER_WEB,
        ...value.SERVER_WEB,
      };
    }
    if (value.SERVER_STREAM) {
      SystemConstants.SERVER_STREAM = {
        ...SystemConstants.SERVER_STREAM,
        ...value.SERVER_STREAM,
      };
    }
    if (value.SERVER_CONVERSION) {
      SystemConstants.SERVER_CONVERSION = {
        ...SystemConstants.SERVER_CONVERSION,
        ...value.SERVER_CONVERSION,
      };
    }
    if (value.SERVER_BIM) {
      SystemConstants.SERVER_BIM = {
        ...SystemConstants.SERVER_BIM,
        ...value.SERVER_BIM,
      };
    }
    if (value.SERVER_IDENTITY) {
      SystemConstants.SERVER_IDENTITY = {
        ...SystemConstants.SERVER_IDENTITY,
        ...value.SERVER_IDENTITY,
      };
    }
    if (value.SERVER_ADEPT) {
      SystemConstants.SERVER_ADEPT = {
        ...SystemConstants.SERVER_ADEPT,
        ...value.SERVER_ADEPT,
      };
    }
    SystemConstants.RENDER = value.RENDER != null
      ? SystemConstants.RENDER_TYPE[value.RENDER]
      : SystemConstants.RENDER;

    SystemConstants.HIDE_HAMBURGER_MENU = value.HIDE_HAMBURGER_MENU == null
      ? SystemConstants.HIDE_HAMBURGER_MENU
      : Boolean(value.HIDE_HAMBURGER_MENU);

    SystemConstants.HOST_TYPE = value.HOST_TYPE ?? SystemConstants.HOST_TYPE;
    const { MIN_STREAMER, MAX_STREAMER, MAX_STREAMER_INACTIVE } = SystemConstants;
    SystemConstants.MAX_STREAMER_INACTIVE = this.setParamNumber(value.MAX_STREAMER_INACTIVE,
      MIN_STREAMER, MAX_STREAMER, MAX_STREAMER_INACTIVE);
    SystemConstants.MAX_NODE = value.MAX_NODE ?? SystemConstants.MAX_NODE;

    // Start PDF Export
    const {
      RENDER, STREAM_CUTOFF_SCALE, BOUNDING_PREVIEW, MEMORY_LIMIT, MAX_TASK, EXP_DIRECTLY, EXP_3DAS2D, PAGE_SIZE_TYPE,
    } = value.PDF_EXPORT ?? SystemConstants.PDF_EXPORT;

    SystemConstants.PDF_EXPORT.RENDER = RENDER != null && SystemConstants.RENDER_TYPE[RENDER]
      ? SystemConstants.RENDER_TYPE[RENDER]
      : SystemConstants.PDF_EXPORT.RENDER;
    SystemConstants.PDF_EXPORT.STREAM_CUTOFF_SCALE = this.setParamNumber(STREAM_CUTOFF_SCALE,
      0, 2, SystemConstants.PDF_EXPORT.STREAM_CUTOFF_SCALE);
    SystemConstants.PDF_EXPORT.BOUNDING_PREVIEW = BOUNDING_PREVIEW != null && SystemConstants.BOUNDING_PREVIEW_MODE[BOUNDING_PREVIEW]
      ? SystemConstants.BOUNDING_PREVIEW_MODE[BOUNDING_PREVIEW]
      : SystemConstants.PDF_EXPORT.BOUNDING_PREVIEW;
    SystemConstants.PDF_EXPORT.MEMORY_LIMIT = this.setParamNumber(MEMORY_LIMIT, 0, null, SystemConstants.PDF_EXPORT.MEMORY_LIMIT);
    SystemConstants.PDF_EXPORT.MAX_TASK = this.setParamNumber(MAX_TASK, 1, 10, SystemConstants.PDF_EXPORT.MAX_TASK);
    SystemConstants.PDF_EXPORT.EXP_DIRECTLY = EXP_DIRECTLY ?? SystemConstants.PDF_EXPORT.EXP_DIRECTLY;
    SystemConstants.PDF_EXPORT.EXP_3DAS2D = EXP_3DAS2D ?? SystemConstants.PDF_EXPORT.EXP_3DAS2D;
    SystemConstants.PDF_EXPORT.PAGE_SIZE_TYPE = PAGE_SIZE_TYPE ?? SystemConstants.PDF_EXPORT.PAGE_SIZE_TYPE;
    // End PDF Export
    SystemConstants.CANVAS_DPI = this.setParamNumber(value.CANVAS_DPI, SystemConstants.MIN_CANVAS_DPI, null, SystemConstants.CANVAS_DPI);
    SystemConstants.PEN_OVERRIDE = value.PEN_OVERRIDE;
    SystemConstants.ALLOW_OVERRIDE_HEADER_FOOTER_WATERMARK = value.ALLOW_OVERRIDE_HEADER_FOOTER_WATERMARK ? value.ALLOW_OVERRIDE_HEADER_FOOTER_WATERMARK : false;

    // [16/05/2024][ADV-7375][phuong_td] Multipler declaration for highlighter
    const LINEWEIGHT_MULTIPLER = value?.HIGHLIGHTER?.LINEWEIGHT_MULTIPLER;
    SystemConstants.HIGHLIGHTER.LINEWEIGHT_MULTIPLER = !LINEWEIGHT_MULTIPLER ? 12 : LINEWEIGHT_MULTIPLER;
    const FILL_OPACITY_MULTIPLER = value?.HIGHLIGHTER?.FILL_OPACITY_MULTIPLER;
    SystemConstants.HIGHLIGHTER.FILL_OPACITY_MULTIPLER = !FILL_OPACITY_MULTIPLER ? 0.5 : FILL_OPACITY_MULTIPLER;
    const {
      MAX_WAIT_TIME, DYNAMIC_CHECK_INTERVAL,
    } = value.WAIT_FOR_RENDER_MARKUP_TIMEOUT ?? SystemConstants.WAIT_FOR_RENDER_MARKUP_TIMEOUT;
    SystemConstants.WAIT_FOR_RENDER_MARKUP_TIMEOUT.MAX_WAIT_TIME = MAX_WAIT_TIME;
    SystemConstants.WAIT_FOR_RENDER_MARKUP_TIMEOUT.DYNAMIC_CHECK_INTERVAL = DYNAMIC_CHECK_INTERVAL;
    // [12/08/2024][ADV-7726][phuong_td] Check for null FOXIT_VIEWER_SETTINGS before setting environment variable value
    const { OPEN_BY_HTTP_RANGE, TILE_CACHE, READ_BLOCK, CHUNK_SIZE } = value.FOXIT_VIEWER_SETTINGS ?? SystemConstants.FOXIT_VIEWER_SETTINGS;
    SystemConstants.FOXIT_VIEWER_SETTINGS.OPEN_BY_HTTP_RANGE = OPEN_BY_HTTP_RANGE ? OPEN_BY_HTTP_RANGE : false;
    SystemConstants.BUILD_VERSION = value.BUILD_VERSION;
    SystemConstants.FOXIT_VIEWER_SETTINGS.TILE_CACHE = TILE_CACHE ?? 2;
    SystemConstants.FOXIT_VIEWER_SETTINGS.READ_BLOCK = READ_BLOCK ?? 8192;
    SystemConstants.FOXIT_VIEWER_SETTINGS.CHUNK_SIZE = CHUNK_SIZE ?? 131072;
    SystemConstants.COPIED_MARKUP_STORAGE=  value.COPIED_MARKUP_STORAGE ? value.COPIED_MARKUP_STORAGE : 1;

    // [12/08/2024][ADV-7726][phuong_td] Check null before setbasapi
    if (SystemConstants.SERVER_WEB) {
      this.webServerService.setBaseApi(protocol, SystemConstants.SERVER_WEB.HOST);
    }
    if (SystemConstants.SERVER_STREAM) {
      this.streamService.setBaseApi(protocol, SystemConstants.SERVER_STREAM.HOST);
    }
    if (SystemConstants.SERVER_CONVERSION) {
      this.conversionService.setBaseApi(protocol, SystemConstants.SERVER_CONVERSION.HOST);
    }
    if (SystemConstants.SERVER_BIM) {
      this.bimService.setBaseApi(protocol, SystemConstants.SERVER_BIM.HOST);
    }
    if (SystemConstants.SERVER_IDENTITY) {
      this.identityService.setBaseApi(protocol, SystemConstants.SERVER_IDENTITY.HOST);
    }

    // JM - In local viewer this cannot be hard coded and needs to be passed in as a parameter.
    if (value.SERVER_ADEPT) {
      this.adeptService.setBaseApi(protocol, value.SERVER_ADEPT.HOST);
    }
    this.viewerVersion = value.VIEWER_VERSION;
    this.viewerCopyright = value.VIEWER_COPYRIGHT;
    this.updateConfig(true);
  }

  private handlerErr(err: any) {
    console.log(`Error get file config: ${err}`);
  }

  private isTestConstruxiv: boolean = false;

  setParamNumber(param: number, minNum: number, maxNum: number, defaultParam: number) {
    let paramValid = defaultParam;
    // eslint-disable-next-line no-restricted-globals
    if (typeof param === 'string' && !isNaN(param)) {
      paramValid = parseFloat(param);
    } else if (typeof param === 'number') {
      paramValid = param;
    }
    if ((minNum && paramValid < minNum) || (maxNum && paramValid > maxNum)) {
      paramValid = defaultParam;
    }
    return paramValid;
  }
}
