/* eslint-disable no-else-return */
/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { MatDialogRef, MatDialog, DialogPosition } from '@angular/material/dialog';
import { UtilExtend, LogType, LogTypeClass } from 'src/app/core/utils/util-extend';
import { PdfCommunicator } from 'src/app/pdf/service/PdfCommunicator';
import { MarkupManager } from 'src/app/pdf/service/markup/MarkupManager';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotifiedData } from 'src/app/core/common/global';
import Util from 'src/app/core/utils/util';
import { ModelInfo } from 'src/app/core/common/ModelInfo';
import { FileInfo } from 'src/app/core/common/main-viewer-common';
import { ContextMenuService } from 'src/app/viewer3d/services/context-menu.service';
import { MultiViewService } from 'src/app/core/services/multi-view.service';
import { take } from 'rxjs/operators';
import { LanguageManagerService } from '../../main-viewer/services/language-manager.service';
import { DataDialogOkCancel, DialogOkCancelComponent } from '../components/dialog/dialog-ok-cancel/dialog-ok-cancel.component';
import { DialogYesNoCancelComponent } from '../components/dialog/dialog-yes-no-cancel/dialog-yes-no-cancel.component';
import { DialogWaitingComponent } from '../components/dialog/dialog-waiting/dialog-waiting.component';
import { DialogInputPasswordComponent } from '../components/dialog/dialog-input-password/dialog-input-password.component';
import { NotificationDialogComponent } from '../components/dialog/notification-dialog/notification-dialog.component';
import { MeasureListComponent } from '../components/dialog/measure/measure-list/measure-list.component';
import { NotifyComponent } from '../components/common/notify/notify.component';
import { DialogAttachmentComponent, dataAttachmentDialog } from '../components/dialog/dialog-attachment/dialog-attachment.component';
import {
  DialogScalingSymbolsComponent,
  dataScalingDialog,
} from '../components/dialog/dialog-scaling-symbols/dialog-scaling-symbols.component';
import { DialogTextSearchComponent } from '../components/dialog/dialog-text-search-result/dialog-text-search-result.component';
import { DialogCloseFileMisscacheComponent } from '../components/dialog/dialog-close-file-misscahe/dialog-close-file-misscache.component';
@Injectable({
  providedIn: 'root',
})
export class DialogExtendService {
  private currentDialog: MatDialogRef<any, any> = null;

  private mouseDownEvent = fromEvent(document, 'mousedown');

  private subs: Subscription;

  public hasCurrentDialog: boolean = false;

  public statusCurrentDialog:boolean=false; // nghia_td ADV-5647 add new

  isShowDialogConfim = false;

  constructor(
    private dialog: MatDialog,
    private languageManagerService:LanguageManagerService,
    private snackBar: MatSnackBar,
  ) {
    UtilExtend.Log(LogType.Init, 'dialog extend', LogTypeClass.Service);
  }

  setCurrentDialog(dialog: MatDialogRef<any, any>) {
    this.currentDialog = dialog;
    this.hasCurrentDialog = true;
    this.currentDialog.afterClosed().subscribe((_) => {
      setTimeout(() => {
        this.hasCurrentDialog = false;
        if (this.statusCurrentDialog) { // nghia_td ADV-5647 add new
          this.hasCurrentDialog = true;
          this.statusCurrentDialog = false;
        }
      }, 100);
    });
  }

  setStatusDialog() { // nghia_td ADV-5647 add new
    this.statusCurrentDialog = true;
  }

  closeContextMenu() {
    // #5287: mouse down does not send to context component
    const listElement = document.getElementsByClassName('container-contextmenu-edit');
    if (listElement?.length > 0) {
      const elementDialog = listElement[0];
      elementDialog && this.closeCurrentDialog();
    }
  }

  closeCurrentDialog() {
    if (this.currentDialog) {
      if (this.currentDialog.componentInstance instanceof MeasureListComponent) return;
      this.currentDialog.close();
      this.subs && this.subs.unsubscribe();
      this.currentDialog = null;
      this.hasCurrentDialog = false;
    }
  }

  addMouseDownEvent(className: string) {
    this.subs = this.mouseDownEvent.subscribe((event: MouseEvent) => {
      const elementDialog = document.getElementsByClassName(className)[0];
      if (elementDialog) {
        const isInside = elementDialog.contains(event.target as Node);
        if (!isInside) {
          this.closeCurrentDialog();
        }
      }
    });
  }

  openDialogConfirm(
    message: string,
    oneAction = false,
    width?: number,
    buttonOk = 'OK',
    buttonCancel = 'Cancel',
    isCloseCurrentDialog = true,
    subMessage: string = '',
  ): MatDialogRef<DialogOkCancelComponent, any> {
    let w = '20rem';
    if (width) {
      w = `${width}px`;
    }
    isCloseCurrentDialog && this.closeCurrentDialog();
    const data: DataDialogOkCancel = {
      textButtonOK: buttonOk, textButtonCancel: buttonCancel, message, justOkAction: oneAction, subMessage,
    };
    const dialog = this.dialog.open(DialogOkCancelComponent, {
      data,
      restoreFocus: false,
      autoFocus: true,
      width: w,
      disableClose: true,
    });
    isCloseCurrentDialog && this.setCurrentDialog(dialog);
    return dialog;
  }

  closeWarning(fileName: string): MatDialogRef<DialogYesNoCancelComponent, any> {
    const translations = this.languageManagerService.getCurentTranslations();
    const dialogRef = this.dialog.open(DialogYesNoCancelComponent, {
      data: {
        title: '',
        notification: '',
        // tslint:disable-next-line: max-line-length
        message: `${fileName}.</br>${translations.DIALOG.CLOSEWARNING.MESSAGE}</br>${translations.DIALOG.CLOSEWARNING.MESSAGE_1}`,
        hasCloseIcon: true,
        yesBtnContent: translations.GENERAL.YES,
        noBtnContent: translations.GENERAL.NO,
      },
      disableClose: true,
    });
    this.setCurrentDialog(dialogRef); // nghia_td ADV-5647 add new
    return dialogRef;
  }

  startWaiting(message?): MatDialogRef<DialogWaitingComponent> {
    const dialogRef = this.dialog.open(DialogWaitingComponent, {
      data: {
        message: `${message}`,
      },
      disableClose: true,
    });
    this.setCurrentDialog(dialogRef);
    return dialogRef;
  }

  showInputPasswordDialog(message?): MatDialogRef<DialogInputPasswordComponent> {
    const dialogRef = this.dialog.open(DialogInputPasswordComponent, {
      data: {
        message: `${message}`,
      },
      disableClose: true,
    });
    this.setCurrentDialog(dialogRef);
    return dialogRef;
  }

  showNotification(
    title:string,
    message: string,
    oneAction = false,
    width?: number,
    buttonOk = 'OK',
  ): MatDialogRef<NotificationDialogComponent, any> {
    let w = '20rem';
    if (width) {
      w = `${width}px`;
    }
    this.closeCurrentDialog();
    const data: DataDialogOkCancel = {
      textButtonOK: buttonOk, message, justOkAction: oneAction,
    };
    const dialog = this.dialog.open(NotificationDialogComponent, {
      data,
      restoreFocus: false,
      autoFocus: true,
      width: w,
      disableClose: true,
    });
    this.setCurrentDialog(dialog);
    return dialog;
  }

  showMeasureList(
    data: {
      webViewer?: Communicator.WebViewer,
      PdfController?: PdfCommunicator,
      MarkupManager?: MarkupManager,
    },
    left: number,
    top: number,
  ) {
    this.closeCurrentDialog();
    const panelClass = 'container-custom-dialog-setting';
    const dialog: MatDialogRef<MeasureListComponent> = this.dialog.open(MeasureListComponent,
      {
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
        // backdropClass: 'backdrop-class-custom',
        backdropClass: 'backdrop-class-modal-form-custom',
        width: '640px',
        height: '425px',
        panelClass,
        // position,
        data,
      });
    this.setCurrentDialog(dialog);
    return dialog;
  }

  getCurrentDialog() {
    return this.currentDialog;
  }

  public notified(
    data: NotifiedData = null,
    duration: number = 5,
  ): MatSnackBarRef<NotifyComponent> {
    return this.snackBar.openFromComponent(NotifyComponent, {
      panelClass: 'custom-container-notify',
      data,
      duration: duration * 1000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }

  async showAttachDialog(data: dataAttachmentDialog, left: number, top: number) {
    const position: DialogPosition = {
      left: `${left}px`,
      top: `${top}px`,
    };
    const panelClass = 'container-custom-dialog-setting';
    this.closeCurrentDialog();
    await Util.delay(50);
    const dialog: MatDialogRef<DialogAttachmentComponent> = this.dialog.open(DialogAttachmentComponent, {
      disableClose: true,
      restoreFocus: false,
      autoFocus: true,
      position,
      backdropClass: 'backdrop-class-modal-form-custom',
      width: '400px',
      height: '420px',
      panelClass,
      data,
    });
    this.setCurrentDialog(dialog);
    return dialog;
  }

  async showScalingDialog(data: dataScalingDialog, left: number, top: number) {
    const position: DialogPosition = {
      left: `${left}px`,
      top: `${top}px`,
    };
    const panelClass = 'container-custom-dialog-setting';
    this.closeCurrentDialog();
    await Util.delay(50);
    const dialog: MatDialogRef<DialogScalingSymbolsComponent> = this.dialog.open(DialogScalingSymbolsComponent, {
      disableClose: true,
      restoreFocus: false,
      autoFocus: true,
      position,
      backdropClass: 'backdrop-class-modal-form-custom',
      width: '400px',
      height: '215px',
      panelClass,
      data,
    });
    this.setCurrentDialog(dialog);
    return dialog;
  }

  async showTextSearchFormat(
    modelInfo: ModelInfo,
    fileInfo: FileInfo,
    contextMenuService: ContextMenuService,
    multiViewService: MultiViewService,
  ) {
    const panelClass = 'container-custom-dialog-setting';
    this.closeCurrentDialog();
    await Util.delay(50);
    const { innerWidth, innerHeight } = window;
    let left = innerWidth / 2 - 630 / 2;
    let top = innerHeight / 2 - 511 / 2;
    if (left < 0) left = 0;
    if (top < 0) top = 0;
    const position: DialogPosition = {
      left: `${left}px`,
      top: `${top}px`,
    };
    const dialog: MatDialogRef<DialogTextSearchComponent> = this.dialog.open(DialogTextSearchComponent, {
      disableClose: true,
      restoreFocus: false,
      autoFocus: true,
      position,
      backdropClass: 'backdrop-class-custom',
      panelClass,
      data: {
        location: {
          x: left,
          y: top,
        },
        modelInfo,
        fileInfo,
        contextMenuService,
        multiViewService,
      },
    });
    this.setCurrentDialog(dialog);
    return dialog;
  }

  async showDialogCloseFileMissCache(fileInfo : any, dataListFile, fileListsService) {
    const panelClass = 'container-custom-dialog-setting';
    let listFiletemp = null;
    this.closeCurrentDialog();
    if (dataListFile.length === 1) {
      listFiletemp = dataListFile;
    } else {
      // [21/3/2024] [lth1004] ADV-7151 [Web Viewer] Warning and disallow users from drawing markup when the cached stream does not exist or cannot be loaded.
      listFiletemp = dataListFile && dataListFile.filter((item) => {
        if (item.data) {
          if (item.data.converter === 'Hoops') {
            if (fileInfo.data && fileInfo.data.viewIdParent) {
              return fileInfo.data.viewIdParent === item.viewId;
            }
            if (fileInfo.viewIdParent) {
              return fileInfo.viewIdParent === item.viewId;
            }
          }
          if (item.data.converter === 'Foxit') {
            if (item.data.viewIdChildren && fileInfo.viewIdParent) {
              return item.viewId === fileInfo.viewIdParent;
            }
          }
        }
        return fileInfo.viewId === item.viewId;
      });
    }
    await Util.delay(50);
    const dialog: MatDialogRef<DialogCloseFileMisscacheComponent> = this.dialog.open(DialogCloseFileMisscacheComponent, {
      disableClose: true,
      restoreFocus: false,
      autoFocus: true,
      // position,
      panelClass,
      data: {
        fileInfo,
        dataListFile,
      },
    });
    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((val) => {
        if (!val) {
          fileListsService.removeAllItemAndAllCopy(listFiletemp);
        }
      });
    this.setCurrentDialog(dialog);
    return dialog;
  }
}
