import { KeyCode } from 'src/app/core/enums/keycode';
import {
  AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild,
  ChangeDetectorRef,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Util from 'src/app/core/utils/util';
import { ConversionService } from 'src/app/core/services/api/conversion.service';
// import { ModelTreeService } from 'src/app/viewer3d/services/model-tree.service';
import { MultiViewService } from 'src/app/core/services/multi-view.service';
import { FileInfo, ResultSearchTree } from 'src/app/core/common/main-viewer-common';
import { Subject } from 'rxjs';
import { UtilExtend } from 'src/app/core/utils/util-extend';
import { takeUntil } from 'rxjs/operators';
import { ModelInfo } from 'src/app/core/common/ModelInfo';
import { DialogExtendService } from 'src/app/shared/services/dialog-extend.service';
import { LanguageManagerService } from '../../../../main-viewer/services/language-manager.service';

@Component({
  selector: 'app-dialog-text-search-result',
  templateUrl: './dialog-text-search-result.component.html',
  styleUrls: ['./dialog-text-search-result.component.scss'],
})
export class DialogTextSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  textSearch: iTextSearch[] = [];

  isHidde = false;

  public showBtnSearch: boolean = false;

  public nextPreMatchEvent$ = new EventEmitter<ResultSearchTree<any>>();

  public mapResultSearchByName: string[] = [];

  public numberCurrentMatch: number = 0;

  searchData: searchData = {
    newStream: null,
    keysearch: '',
    matchCase: false,
    matchWholeWord: false,
  }

  isFocusInput: boolean = false;

  currenNode: string;

  isSearching = false;

  disablePre = true;
  
  @ViewChild('inputSearch') inputSearch: ElementRef<HTMLInputElement>;

  @HostListener('document:keydown', ['$event'])
  public onKeydown(event) {
    // [08/03/2024][phuong_td][ADV-7470] The button will not be Turned On when the focus is on another element
    if (event.altKey && this.isFocusInput) {
      switch (event.key) {
        case KeyCode.c:
          this.toggleFunctionSearch('MATCH_CASE');
          break;
        case KeyCode.w:
          this.toggleFunctionSearch('MATCH_WHOLE_WORD');
          break;
        default:
          break;
      }
    }
    if (event.code === KeyCode.escape) {
      if (this.isFocusInput || this.getText() !== '') {
        this.clearTextInput();
      } else {
        this.closeDialog();
      }
    }
    if (event.code === KeyCode.enter) { this.search(); }
    if (event.ctrlKey) {
      if (event.key === KeyCode.f || event.key === KeyCode.F) {
        event.preventDefault();
        this.focus();
      }
    }
  }

  private onDestroy$ = new Subject<any>();

  modelInfo: ModelInfo;

  disabledMatchWholeWord = true;

  disabledMatchCase = true;

  constructor(public dialogRef: MatDialogRef<DialogTextSearchComponent>,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private dialogExtendService: DialogExtendService,

    @Inject(MAT_DIALOG_DATA) public data: dataTextSearchResultDialog,
    private languageManagerService: LanguageManagerService,
    public conversion: ConversionService) {
  }

  ngAfterViewInit(): void {
    this.data.multiViewService.viewActive$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((view) => {
        if (view?.data?.isComparisonResult) {
          this.closeDialog();
        }
        this.textSearch = [];
        this.unsetCallBack();
        this.initCallback();
      });
  }

  ngOnDestroy(): void {
    this.unsetCallBack();
    UtilExtend.onDestroy(this.onDestroy$);
  }

  async ngOnInit(): Promise<void> {
    // this.updatePosition();
  }

  selectNode(data) {
    this.currenNode = data?._selection?._nodeId as string;
    this.updateDetectChange();
  }

  initCallback() {
    this.modelInfo = this.data.multiViewService.getModelInfo();
    this.modelInfo.webviewer.setCallbacks({
      selection: (data) => this.selectNode(data),
    });
  }

  unsetCallBack() {
    this.modelInfo?.webviewer.unsetCallbacks({
      selection: (data) => this.selectNode(data),
    });
  }

  closeDialog() {
    this.dialogExtendService.closeCurrentDialog();
    // this.dialogRef.close();
  }

  minimize() {
    this.isHidde = !this.isHidde;
    this.updatePosition();
    if (this.isHidde) {
      this.searchData.keysearch = this.getText();
    } else {
      this.setText(this.searchData.keysearch);
    }
  }

  updatePosition() {
    const left = this.data?.location?.x | 0;
    const top = this.data?.location?.y | 0;
    const width = 630;
    const height = 511;
    const temp = {
      left, top, width, height,
    };
    const { dialogPosition } = Util.newSafePositionDialog(temp);
    this.dialogRef.updatePosition(dialogPosition);
  }

  onSubmit() {
    // onSubmit
  }

  async selectionChange(item: iTextSearch) {
    if (item) {
      const modelInfo = this.data.multiViewService.getModelInfo();
      const isVisible = modelInfo.webviewer.model.getNodeVisibility(item.nodeId);
      if (isVisible) {
        modelInfo.webviewer.ignoreTreeSync = true;
        modelInfo.webviewer.selectionManager.selectNode(parseInt(item.nodeId, 10));
        this.currenNode = item.nodeId;
        const numberCurrentMatch = this.textSearch.findIndex(i => i.nodeId === item.nodeId) + 1;
        this.setNumberCurrentMatch(numberCurrentMatch);
        Util.delay(500).then(() => {
          this.data.contextMenuService.focus();
          modelInfo.webviewer.ignoreTreeSync = false;
        });
      }
    }
  }

  resultsSearchEvent(results: any[]) {
    this.mapResultSearchByName = results.map((re) => re.node.name);
    this.cdRef.detectChanges();
  }

  showSearchInputEvent(state: boolean) { }

  trackByVirtualFor = (index: number, item: any) => item.nodeId;

  toggleFunctionSearch(typeFunc: 'MATCH_WHOLE_WORD' | 'MATCH_CASE') {
    switch (typeFunc) {
      case 'MATCH_CASE':
        this.searchData.matchCase = !this.searchData.matchCase;
        break;
      case 'MATCH_WHOLE_WORD':
        this.searchData.matchWholeWord = !this.searchData.matchWholeWord;
        break;
      default:
        break;
    }
    this.updateDetectChange();
  }

  updateDetectChange(): void {
    this.cdRef.detectChanges();
  }

  clearTextInput() {
    this.searchData.keysearch = '';
    if (this.inputSearch) {
      const inputElement: HTMLInputElement = this.inputSearch.nativeElement;
      inputElement.value = '';
      inputElement.focus();
      this.isFocusInput = true;
    }
    this.checkDisableMatchCaseAndMatchWholeWord();
    this.updateDetectChange();
  }

  focus() {
    const inputElement: HTMLInputElement = this.inputSearch.nativeElement;
    if (inputElement) {
      inputElement.select();
    }
    this.isFocusInput = true;
  }

  blur() {
    this.isFocusInput = false;
  }

  getText() {
    if (this.inputSearch) {
      const inputElement: HTMLInputElement = this.inputSearch.nativeElement;
      return inputElement.value;
    }
    return '';
  }

  setText(text: string) {
    if (this.inputSearch) {
      const inputElement: HTMLInputElement = this.inputSearch.nativeElement;
      inputElement.setAttribute('value', text);
    }
  }

  async search() {
    this.searchData.newStream = this.data.multiViewService.viewActive.data;
    this.searchData.keysearch = this.getText();
    const data = await this.conversion.textSearch(this.searchData).toPromise();
    const modelInfo = this.data.multiViewService.getModelInfo();
    const result = [];
    data.forEach(node => {
      const isVisible = modelInfo.webviewer.model.getNodeVisibility(node.nodeId);
      if (isVisible) {
        result.push(node)
      }
    });
    this.setNumberCurrentMatch(0)
    this.currenNode = null;
    this.textSearch = result;
    this.updateDetectChange();
  }

  nextMatch() {
    this.navChange(1);
  }

  previousMatch() {
    this.navChange(-1);
  }

  navChange(value: number) {
    let index = this.numberCurrentMatch + value;
    if (index > this.textSearch.length) {
      index = 0
    }
    if (index < 0) {
      index = this.textSearch.length;
    }
    this.setNumberCurrentMatch(index);
    const item = this.textSearch[index - 1];
    this.selectionChange(item);
    this.scrollToItem(item);
  }

  onInput($event: any) {
    this.searchData.keysearch = this.getText();
    this.checkDisableMatchCaseAndMatchWholeWord();
  }

  scrollToItem(item) {
    if (item) {
      const node: any = document.getElementById(`search-item-${item.nodeId}`);
      node?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  setNumberCurrentMatch(value: number) {
    this.numberCurrentMatch = value;
    if (value === 1 || value === 0) {
      this.disablePre = true;
    } else {
      this.disablePre = false;
    }
  }

  checkDisableMatchCaseAndMatchWholeWord() {
    this.disabledMatchCase = !this.searchData.keysearch;
    this.disabledMatchWholeWord = !this.searchData.keysearch;
    if (!this.searchData.keysearch) {
      this.searchData.matchCase = false;
      this.searchData.matchWholeWord = false;
    }
  }
}
export interface dataTextSearchResultDialog {
  dataInput?: any;
  location?: any;
  multiViewService?: any;
  modelInfo?: any;
  fileInfo?: FileInfo
  contextMenuService?: any;
}

export interface iTextSearch {
  nodeId?: string;
  text?: string;
}

export interface searchData {
  newStream: FileInfo;
  keysearch: string;
  matchCase: boolean;
  matchWholeWord: boolean;
}
